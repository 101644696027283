.no-border-round {
  border-radius: 0 !important;
}
.margin-top-section {
  margin-top: 96px;
}
.bg-gray {
  background-color: #f6f6f6 !important;
}

.middle {
  padding: 100px;
}

.emailLoader {
  position: absolute;
  width: calc(100% + 50px);
  height: calc(100% + 50px);
  top: -25px;
  left: -25px;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  display: none;
  transform: scale(0.77);
}

.uil-ring-css {
  background: none;
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  top: 50%;
  margin-top: -100px;
}

form {
  display: block;
  margin-top: 0em;
}

.no-margin-lr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#success-free30daytrial {
  clear: both;
  margin: 0 15px 20px;
  text-align: center;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
  border-radius: 5px;
  padding: 15px 0;
}

.no-margin-bottom {
  margin-bottom: 10px !important;
}
.form-group {
  margin-bottom: 15px;
  width: 500px;
  padding: 4px;
}
#free30daytrialform {
  margin-left: 150px;
  padding: 10px;
}
.text-edit {
  font-size: 11px;
}
input,
textarea,
select {
  border: 1px solid #dfdfdf;
  letter-spacing: 1px;
  font-size: 11px;
  padding: 8px 15px;
  width: 100%;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  max-width: 100%;
  resize: none;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input {
  line-height: normal;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}
@media screen and (max-width: 1110px) {
  .form-group {
    margin-bottom: 15px;
    width: 300px;
    padding: 4px;
  }
  #free30daytrialform {
    margin-left: 0px;
    padding: 0px;
  }
}
