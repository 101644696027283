.small-icon {
  font-size: 25px !important;
}
.black-text {
  color: #000 !important;
}
.icon-edit {
  width: 50px;
  height: 100%;
}
.margin-four {
  margin-top: 4% !important;
  margin-bottom: 4% !important;
}
.footer-bottom {
  position: relative;
  top: 1px;
}
.bg-dark-gray {
  background: #252525 !important;
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.margin-three {
  margin-top: 3% !important;

  height: 100px;
}
.copyright {
  font-size: 11px;
  text-transform: uppercase;
  color: #888888;
}
.letter-spacing-1 {
  letter-spacing: 1px !important;
  margin-top: 40px;
}
